<template>
  <div class="print">
    <div class="print-head">
      <div class="print-head-title">
        <div @click="exit" class="print-head-nav none">
          <van-icon name="exchange" />退出
        </div>
        <div class="title_box">
            <div class="title1">{{ user.roleName == '曲一线' ? '曲一线' : user.pressName}}</div>
            <div class="sub_title">{{ user.realName}}</div>
        </div>
        <span class="none"></span>
      </div>
      <div class="print-head-info">
      </div>
    </div>
    <template>
        <div v-if="isShowScan">
            <div class="btn-group">
                <van-button size="large" round type="info" @click="clickQrcode">扫一扫</van-button>
            </div>
        </div>
        <div v-else>
            <div class="btn-group" v-if="this.user.roleName == '印厂业务员' || this.user.roleName == '印刷操作员'">
                <van-button size="large" round type="info" @click="toClick('1')">生产使用</van-button>
            </div>
            <div class="btn-group" v-if="this.user.roleName == '印厂业务员' || this.user.roleName == '仓库角色' || this.user.roleName == '仓库调拨员'">
                <van-button size="large" round type="info" @click="toClick('2')">调拨使用</van-button>
            </div>
            <div class="btn-group" v-if="this.user.roleName == '印厂业务员' || this.user.roleName == '仓库角色' || this.user.roleName == '仓库调拨员'">
                <van-button size="large" round type="info" @click="toClick('3')">调拨记录</van-button>
            </div>
        </div>
    </template>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      user: "",
      token: "",
      list: [],
      key: "",
      searchList: [],
      isShowScan:false
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      console.log(this.user);
      // 内部人员,只展示扫一扫
      if(this.user.roleName == '曲一线'){
        this.isShowScan = true
      }else{
        this.isShowScan = false
      }
      this.token = { Authorization: "Bearer " + this.user.token };
      console.log('tokens',this.token)
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    exit() {
      Dialog.confirm({
        title: "提示",
        message: "确定退出登录？",
      })
        .then(() => {
          this.$cookies.remove("user");
          setTimeout(() => {
            this.$router.replace({
              name: "login",
            });
          }, 1000);
        })
        .catch(() => {
          //console.log("cancel");
        });
    },
    toClick(index) {
        // // 去二维码页面
        // Toast.loading({
        //     message: "加载中...",
        // });
        switch (index) {
            case "1":
                setTimeout(() => {
                    this.$router.push({
                        name: "deductA",
                    });
                }, 1000);
                break;
            case "2":
            setTimeout(() => {
                    this.$router.push({
                        name: "deduct",
                        query: {
                            type: "2",
                        }
                    });
                }, 1000);
                break;
            case "3":
            setTimeout(() => {
                    this.$router.push({
                        name: "deduct",
                        query: {
                            type: "3",
                        }
                    });
                }, 1000);
                break;
        }
        
    },
    clickQrcode() {
        // 直接拉起二维码
        try {
            this.$wechat.scanQRCode().then((res) => {
                let { resultStr, errMsg } = res;
                console.log('res1',res);
                if (errMsg == "scanQRCode:ok") {
                    if (resultStr) {
                        this.$router.push({
                            name:'details',
                            query: {
                                id:resultStr,
                            }
                        })
                    }
                } else {
                    Toast('扫码失败,请重新扫码！')
                }
            });
        } catch (error) {
            console.log('error',error)
        }
    },
  },
};
</script>
<style lang="scss" scoped>
    .btn-group{
        margin: 40px;
        padding: 40px;
    }
    .title1{
        height: 60px;
    }
    .sub_title{
        font-size: 30px;
    }
</style>
