let baseUrl = "https://yz.api.53online.cn/api/";
// let baseUrl = "http://172.10.1.205:3000/mock/93/api/";
export default {
  //登录
  loginUrl: baseUrl + "Login/Login",

  //获取卷筒码详细信息,扫码接口
  getOutinUrl: baseUrl + "Materials/GetReelCodeDetail",
  //保存生产使用的卷筒码-保存信息
  saveOutinUrl: baseUrl + "Materials/SaveUseReelCode",
  //保存调拨使用的卷筒码-保存信息
  savedbOutinUrl: baseUrl + "Materials/SaveDBReelCode",


  //材料出入库-上传凭证
  uploadfileOutinUrl:
    baseUrl + "MaterialsBuyOrderOutIn/UploadFileYwtMaterialsBuyOrderOutIn",

  //印刷任务列表     主列表
  flowmainUrl: baseUrl + "Materials/GetTaskList",
  //获取卷筒码详细信息  详情列表
  flowmainlistUrl: baseUrl + "Materials/GetReelCodeDetail",
  ///获取印厂付印单号列表明细 搜索用
  flowlistUrl: baseUrl + "Flow/GetFydNoList",
  //获取印厂付印单号列表明细  进度
  progressUrl: baseUrl + "Flow/GetFlowDetailList",
  //prpgressUrl  更新进度
  updateprogressUrl: baseUrl + "Flow/SaveUpdateFlowComletionRate",

  //回单分页列表
  deductlistUrl: baseUrl + "Materials/GetDBList",
  //回单保存
  deductsaveUrl: baseUrl + "DeductMoney/Save",
  //回单上传图
  deductUploadUrl: baseUrl + "DeductMoney/UploadFileOSSPressDeductMoney",

  //获取印刷任务部件明细列表
  getMonadDetailListUrl: baseUrl + "Materials/GetYzMonadDetailList",

  // 查看生产使用的卷筒码
  getUseReelCodeListUrl:baseUrl+ "Materials/GetUseReelCode",

  // 获取仓库的调拨任务记录
  GetDBLogListUrl:baseUrl+"Materials/GetDBLogList",

  // 获取仓库的调拨卷码明细记录
  GetDBLogReelCodeDetailUrl:baseUrl+"Materials/GetDBLogReelCodeDetail"
};
