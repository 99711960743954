<template>
  <div class="mater">
    <van-form v-if="this.judgeType == '2'">
        <van-field
            v-model="outTime"
            required
            label="出库日期"
            right-icon="arrow-down"
            placeholder="请选择出库日期"
            readonly
            @click="showDatePicker = true"
            :rules="[{ required: true }]"
        />
        <van-popup v-model="showDatePicker" position="bottom">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :min-date="minDate"
                confirm-button-text="确定"
                cancel-button-text="取消"
                :max-date="maxDate"
                @cancel="showDatePicker = false"
                @confirm="receivingTimeConfirm"
            />
        </van-popup>

        <van-field
            v-model="DBInNum"
            label="调拨吨数"
            type="number"
            required
            placeholder="请输入调拨数量"
            :rules="[{ required: true }]"
        />
        <van-field
            v-model="ItemNumber"
            label="件数/卷数"
            required
            type="number"
            placeholder="请输入调拨数量"
            :rules="[{ required: true, }]"
        />
        <van-field
            v-model="Quality"
            required
            rows="1"
            autosize
            label="入库质量"
            type="textarea"
            placeholder="请输入留言"
            show-word-limit
        />
        <van-field
            v-model="showGram"
            required
            readonly
            label="克重"
        />
        <van-field
            v-model="showWidth"
            required
            readonly
            label="宽"
        />
        <van-field
            label="卷筒码"
            label-width="50px"
            placeholder="请扫描二维码"
            required
        >
            <template #input >
                <div class="itemStyle">
                    <div v-for="(item,index) in numberList" :key="index">
                        <span>{{ index + 1 }}、</span>
                            {{ item }} 
                        <span @click="deleteItem(index)">
                            <van-icon color="#f00" name="cross" />
                        </span>
                        <span v-if="getRealWeight(index) != ''" class="kgStyle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;实际{{getRealWeight(index)}}公斤</span>
                        <span v-else class="kgStyle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平均{{getWeight(index)}}公斤</span>
                    </div>
                 </div>
            </template>
        </van-field>
        <van-button
            class="qrcode1"
            round
            block
            type="info"
            @click.stop="clickQrcode"
            :disabled="qrcodeBtn"
            ><van-icon name="scan" />微信扫二维码</van-button
        >
        
        <van-button 
            round
            block
            type="info"
            @click.stop="onSubmit"
            :disabled="numberList.length == 0 || isClickBtn || keepbtn"
            >保存</van-button
        >
    </van-form>
    <van-form v-if="this.judgeType == '1'">
      <van-field
        label="印制项目"
        label-width="60px"
        required
        right-icon="arrow-down"
        placeholder="请选择印制项目"
        readonly
        @click="selectShowPopup = true"
      >
            <template #input>
                <div>
                    <div :class="judgeColor?'g_text':'g_text_gray'">
                        {{ printItemName }}
                    </div>
                    <div :class="judgeColor?'g_color':'g_color_gray'" v-if="getPaperG|| paperWidth||paperTitle">{{ getPaperG }}克{{ paperWidth }}{{ paperTitle }}</div>
                </div>
                
                
            </template>
        </van-field>
      <van-popup
        v-model="selectShowPopup"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          show-toolbar
          :columns="columns"
          default-index="0"
          @confirm="searchBtn"
          @cancel="selectShowPopup = false"
        />
      </van-popup>
        <van-field
            label="卷筒码"
            label-width="50px"
            placeholder="请扫描二维码"
            required
        >
            <template #input >
                <div class="itemStyle">
                    <div v-for="(item,index) in numberList" :key="index">
                        <span>{{ index + 1 }}、</span>
                            {{ item }} 
                        <span @click="deleteItem(index)">
                            <van-icon color="#f00" name="cross" /></span>
                            <span v-if="getRealWeight(index) != ''" class="kgStyle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;实际{{getRealWeight(index)}}公斤</span>
                            <span v-else class="kgStyle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平均{{getWeight(index)}}公斤</span>
                        </div>
                 </div>
            </template>
        </van-field>
        <van-button
            class="qrcode1"
            round
            block
            type="info"
            @click.stop="clickQrcode"
            :disabled="qrcodeBtn || reClickbtn"
            ><van-icon name="scan" />微信扫二维码</van-button
        >
        <van-button 
            round
            block
            type="info"
            @click.stop="onSubmit"
            :disabled="keepbtn || isClickBtn1"
        > 保 存 </van-button>
        <van-field
            v-if="historyList.length > 0"
            label="已使用卷筒码"
            label-width="50px"
        >
            <template #input >
                <div class="itemStyle1">
                    <div v-for="(item,index) in historyList" :key="index">
                        {{ item }}
                    </div>
                 </div>
            </template>
        </van-field>
    </van-form>
  </div>
</template>

<script>
import { Dialog, Toast, Notify } from "vant";
import qs from "qs";

export default {
  name: "materials",
  data() {
    return {
      user: "",
      token: "",
      numberList:localStorage.getItem('numbers') ? JSON.parse(localStorage.getItem('numbers')) :[],// 扫码后展示的列表
      judgeType:this.$route.query.id == undefined ? "" : this.$route.query.id,
      taskId:this.$route.query.taskId == undefined ? "" : this.$route.query.taskId,// 用于判断是否需要清空idsList
      outInId:this.$route.query.outInId == undefined ? "" : this.$route.query.outInId,// 用于判断是否需要清空idsList
      showGram:this.$route.query.gram == undefined ? '' : this.$route.query.gram, // 克数
      showWidth:this.$route.query.width == undefined ? '' : this.$route.query.width, // 宽度
      showDatePicker: false,
      currentDate: new Date(),
      maxDate: new Date(2030, 11, 31),
      minDate: new Date(1950, 0, 1),
      outTime: "", //单据签收日期
      DBInNum: "", //调拨数量
      ItemNumber: "", //件数/卷数
      Quality: "合格", //入库质量
      keepbtn:false,

      selectShowPopup: false, // 下拉框展示
      columns: [],// 下拉列表总数据
      printItemId:'',
      printItemName:'',
      getPaperG: '',
      reClickbtn:false,
      avgWeight:'0',
      avgWeightList:localStorage.getItem('avgList') ? JSON.parse(localStorage.getItem('avgList')) :[], // 平均总数据
      realWeightList:localStorage.getItem('realList') ? JSON.parse(localStorage.getItem('realList')) :[], // 实际总数据
      paperTitle:'',
      paperWidth:'',
      shaomaG:'',
      judgeColor:false,
      historyList:[],// 历史展示列表
    };
  },
  mounted() {
    if(this.judgeType == '2'){
        this.getPaperG = this.$route.query.gram || '';
        this.showGram = this.$route.query.gram || '';
        this.showWidth = this.$route.query.width || '';
        console.log('🍅',this.$route.query);
    }else{
        console.log('🍌',this.$route.query);
    }
    
    // 默认展示时间
    this.outTime = this.timeFormat(new Date());
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
    } else {
      this.$router.replace({
        name: "login",
      });
    }
    console.log('-------打印id',this.taskId)
    // 如果存在存储，下次再进来，如果还是这个忙不处理，如果不是这个，就执行清空操作
    if (this.taskId && this.taskId != localStorage.getItem("taskId")) {
        localStorage.setItem("taskId", this.taskId);
        this.numberList = [];
        this.avgWeightList = [];
        this.realWeightList = [];
        localStorage.setItem('codes',JSON.stringify([]));
        localStorage.setItem('numbers',JSON.stringify([]));
        localStorage.setItem('avgList',JSON.stringify([]));
        localStorage.setItem('realList',JSON.stringify([]));
    }else if (this.outInId && this.outInId != localStorage.getItem("outInId")) {
        localStorage.setItem("outInId", this.outInId);
        this.numberList = [];
        this.avgWeightList = [];
        this.realWeightList = [];
        localStorage.setItem('codes',JSON.stringify([]));
        localStorage.setItem('numbers',JSON.stringify([]));
        localStorage.setItem('avgList',JSON.stringify([]));
        localStorage.setItem('realList',JSON.stringify([]));
    }else{
        console.log('啥也不动')
    }
    if(this.judgeType == '1'){
        this.getSelectList()
        this.getReelCodeList()
    }
  },
  computed: {
    qrcodeBtn(){
        if(this.judgeType == '1'){
            return !(this.printItemName);
        }else if(this.judgeType == '2'){
            return !(this.outTime && this.DBInNum && this.ItemNumber && this.Quality);
        }
    },
    isClickBtn1(){
        return this.printItemName == '' || this.numberList.length == 0;
    },
    isClickBtn(){
        return !((Number(this.ItemNumber) <= this.numberList.length))
    },
  },  
  methods: {
    clickQrcode() {
        // 点击时不能
        this.reClickbtn = true
        console.log('走了这里',this.judgeType)
        try {
            this.$wechat.scanQRCode().then((res) => {
            let { resultStr, errMsg } = res;
            console.log('res1',res);
            if (errMsg == "scanQRCode:ok") {
                this.reClickbtn = false
                if (resultStr) {
                    //访问相关接口拿数据
                    this.$api.print
                        .getOutIn({ code: resultStr }, this.token)
                        .then((res) => {
                        let { tag, data, message } = res;
                        console.log('res2',res)
                        // 将扫码后，记录一下当前的克数
                        this.shaomaG = data.gram || '';
                        // data为null是代表扫码的条码不存在
                        if(data == null){
                            Toast('二维码不存在')
                            return
                        }
                        if(data && (data.gram != this.getPaperG) ){
                            console.log('打印出来的纸张',data.gram,'---',this.getPaperG)
                            Toast('当前纸张与任务单要求不符')
                            return
                        }
                        if (tag == 1) {
                            console.log('res5',res)
                            //加入列表之前，先判断数据是否存在，不存在则加入列表
                            if(this.numberList.indexOf(res.data.number) == -1){
                                this.numberList.push(res.data.number)
                                this.avgWeightList.push(data.avgWeight)
                                this.realWeightList.push(data.weight)
                                localStorage.setItem('numbers',JSON.stringify(this.numberList));
                                localStorage.setItem('avgList',JSON.stringify(this.avgWeightList));
                                localStorage.setItem('realList',JSON.stringify(this.realWeightList));
                            }
                            console.log('dizhi----',this.numberList)
                        } else {
                            //扫码后查询数据出错
                            Dialog.alert({
                            title: "查询发生错误",
                            message: message,
                            }).then(() => {
                            // 重新扫码
                            });
                        }
                    });
                }
            } else {
                Notify("打开扫一扫失败");
            }
        });
        } catch (error) {
            console.log('error',error)
        }
    },
    clickNumberList(){
        if(this.judgeType == '1'){
            if(this.shaomaG != this.getPaperG ){
                this.keepbtn = false
                console.log('打印出来的纸张1',this.shaomaG,'---',this.getPaperG)
                Toast('当前纸张与任务单要求不符')
                return
            }else{
                this.keepbtn = true
            }
            // 来自主页
            this.$api.print
                .saveOutIn({ 
                    codes: this.numberList.join(','),// 卷筒码id  多个 逗号连接
                    taskId:this.$route.query.taskId == undefined ? "" : this.$route.query.taskId,// 任务id
                    taskName:this.$route.query.taskName == undefined ? "" : this.$route.query.taskName,// 任务名称 
                    printItemId:this.printItemId,// 印制项目id
                    printItemName:this.printItemName,// 印制项目名称
                    subtitle:this.getPaperG + '克-'+ this.paperWidth + '-' + this.paperTitle,
                }, this.token)
                .then((res) => {
                    const { tag,message } = res;
                    if(tag == 1){
                        // 清楚缓存
                        localStorage.removeItem('numbers');
                        localStorage.removeItem('codes');
                        localStorage.removeItem('avgList');
                        localStorage.removeItem('realList');
                        Toast(message);
                        this.$router.go(-1);
                    }else{
                        this.keepbtn = false
                        Toast(message);
                    }
                })
        }else if(this.judgeType == '2'){
            // 来自转拨详情页
            this.$api.print
                .savedbOutIn({ 
                    codes: this.numberList.join(','), // 卷码id  扫码得到
                    outInId:this.$route.query.outInId == undefined ? "" : this.$route.query.outInId,// 调拨单id
                    outInNo:this.$route.query.outInNo == undefined ? "" : this.$route.query.outInNo,// 调拨单编号
                    outTime:this.outTime,// 单据签收日期
                    DBInNum:this.DBInNum,// 调拨数量
                    ItemNumber:this.ItemNumber,// 件数/卷数
                    Quality:this.Quality,// 入库质量
                }, this.token)
                .then((res) => {
                    const { tag,message } = res;
                    if(tag == 1){
                        // 提示保存成功
                        Toast(message);
                        this.$router.go(-1);
                        // 清楚缓存
                        localStorage.removeItem('numbers');
                        localStorage.removeItem('codes');
                        localStorage.removeItem('avgList');
                        localStorage.removeItem('realList');
                    }else{
                        // 提示保存失败,让按钮可点击
                        this.keepbtn = false
                        Toast(message);
                    }
                })

        }  
    },
    onSubmit(){
        this.keepbtn = true
        if(this.judgeType == '1'){
            this.clickNumberList()
        }else if(this.judgeType == '2'){
            // 先判断数量是否一致
            if (this.numberList.length == Number(this.ItemNumber)) {
                this.clickNumberList()
            } else {
                this.keepbtn = false
                Toast('件数/卷数必须跟扫码后的数量一致')
            }
        }
    },
    receivingTimeConfirm(e) {
      this.outTime = this.timeFormat(new Date(e));
      this.showDatePicker = false;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },
    deleteItem(index){
        // 删除numberList中的该条数据




        this.numberList.splice(index,1)
        this.avgWeightList.splice(index,1)
        this.realWeightList.splice(index,1)
        console.log('删除后的numberList',this.numberList)
        localStorage.setItem('numbers',JSON.stringify(this.numberList));
        localStorage.setItem('avgList',JSON.stringify(this.avgWeightList));
        localStorage.setItem('realList',JSON.stringify(this.realWeightList));
    },
    searchBtn(value) {
        console.log('value', value);
      this.selectShowPopup = false;
      this.printItemId = value.value;
      this.printItemName = value.text;
      this.getPaperG = value.gram;
      this.paperTitle = value.title;
      this.paperWidth = value.width;
      // 
      if(this.getPaperG != this.shaomaG && this.shaomaG){
        this.judgeColor = true
      }else{
        this.judgeColor = false
      }
      console.log('打印出来this.',this.judgeColor)
    },
    getSelectList(){
        this.$api.print
        .getDeductList({ taskName:this.$route.query.taskName }, this.token)
        .then((res)=>{
            let { tag, data } = res;
            if(tag == 1){
                if(data){
                    this.columns = data.map(ele=>{
                        return {
                            text:ele.printItemName,
                            value:ele.printItemId,
                            gram:ele.gram,
                            title:ele.title,
                            width:ele.width,
                        }
                    })
                }
            }
        })
    },
    // 实际重量
    getRealWeight(index){
        return this.realWeightList[index]
    },
    // 平均重量
    getWeight(index){
        return this.avgWeightList[index]
    },
    getReelCodeList(){
        this.$api.print
        .getUseReelCodeList({ taskId:this.$route.query.taskId }, this.token)
        .then((res)=>{
            let { tag, data } = res;
            if(tag == 1){
                if(data){
                   this.historyList = data
                   console.log('this.historyList',this.historyList)
                }
            }
        })
    }
  },
};
</script>

<style lang="scss">
.mater {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
    .van-field__body{
        align-items: flex-start;
    }
    .itemStyle{
        font-size: 35px;
        font-weight: bold;
    }
    .itemStyle1{
        font-size: 35px;
        font-weight: bold;
        color:#aaa
    }
    .error-icon{
        color: red;
    }
    .kgStyle{
        opacity: 0.4;
    }
    .g_text{
        font-size: 35px;
        font-weight: bold;
        color: #ee0a24 !important
    }
    .g_text_gray{
        font-size: 35px;
        font-weight: bold;
        color: #323233 !important;
    }
    .g_color{
        opacity: 0.4;
        font-size: 35px;
        font-weight: bold;
        color: #ee0a24 !important
    }
    .g_color_gray{
        opacity: 0.4;
        font-size: 35px;
        font-weight: bold;
        color: #323233 !important;
    }
  .qrcode {
    margin-top: 100px;
    background: linear-gradient(to right, #3f8fff, #0750ff);

    .van-button__text {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .van-icon-scan {
      margin-right: 10px;
      font-size: 28px;
    }
  }
  .qrcode1{
    margin-bottom: 100px;
  }

  &-info {
    font-size: 24px;
    color: #666;

    &-mbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-nav {
      width: 140px;
      font-size: 20px;
      color: #333;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      .van-icon {
        font-size: 48px !important;
      }
    }
    .none {
      width: 140px;
    }

    &-title {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      font-weight: normal;
      text-align: center;
    }

    &-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 0;

      &:not(&:nth-child(1)) {
        border-bottom: 1px solid #cecece;
      }

      span {
        width: 200px;
        text-align: right;
      }
    }
  }
  &-form {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    input::placeholder,
    textarea::placeholder {
      color: #fff;
    }

    .van-cell {
      background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
      padding: 15px 0;
      color: #333 !important;
      &::after {
        border-bottom: 1px solid #cecece;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .van-field__label {
      width: 8em;
      color: #333 !important;
      text-align: right;
    }

    &-upload {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 15px 0;
      border-bottom: 1px solid #cecece;

      span {
        width: 8.5em;
        text-align: right;
      }
      .van-uploader {
        margin-left: 20px;

        .van-uploader__preview {
          margin: 0 6px 6px 0;
        }
      }
    }
    .van-button {
      margin: 30px 0;
      background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
      //background: linear-gradient(to right, #ffbd6e, #ffad4a);
      border: 0;
    }
  }
}
</style>
