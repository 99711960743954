<template>
    <div class="mater">
      <van-form>
          <van-field
              label="卷筒码"
              label-width="50px"
              required
          >
              <template #input >
                    <div class="itemStyle" v-if="numberList.length > 0">
                        <div v-for="(item,index) in numberList" :key="index">
                            <span>{{ index + 1 }}、</span>
                            {{ item.number }} 
                            <!-- <span @click="deleteItem(index)">
                                <van-icon color="#f00" name="cross" />
                            </span> -->
                            <span v-if="item.weight != ''" class="kgStyle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;实际{{item.weight}}公斤</span>
                            <div v-else class="kgStyle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平均{{item.avgWeight}}公斤</div>
                        </div>
                   </div>
              </template>
          </van-field>
      </van-form>
      <div v-if="numberList.length <= 0" class="print-nolist">
            <van-empty description="暂无数据" />
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "materials",
    data() {
      return {
        user: "",
        token: "",
        numberList:[],// 扫码后展示的列表
        outInId:this.$route.query.outInId == undefined ? "" : this.$route.query.outInId,// 用于判断是否需要清空idsList
      };
    },
    mounted() {
      if (this.$cookies.isKey("user")) {
        this.user = this.$cookies.get("user");
        //console.log(this.user);
        this.token = { Authorization: "Bearer " + this.user.token };
        this.getSelectList();
      } else {
        this.$router.replace({
          name: "login",
        });
      }
    }, 
    methods: {
      getSelectList(){
          this.$api.print
          .getDBLogReelCodeDetail({ outInId:this.outInId }, this.token)
          .then((res)=>{
            let { tag, data } = res;
            if(tag == 1){
                if(data){
                    this.numberList = data;
                    console.log('打印数据-----',data)
                }
            }
        })
      },
    },
  };
  </script>
  
  <style lang="scss">
  .mater {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
      .van-field__body{
          align-items: flex-start;
      }
      .itemStyle{
          font-size: 35px;
          font-weight: bold;
      }
      .itemStyle1{
          font-size: 35px;
          font-weight: bold;
          color:#aaa
      }
      .error-icon{
          color: red;
      }
      .kgStyle{
          opacity: 0.4;
      }
  
    &-info {
      font-size: 24px;
      color: #666;
  
      &-mbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-nav {
        width: 140px;
        font-size: 20px;
        color: #333;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        .van-icon {
          font-size: 48px !important;
        }
      }
      .none {
        width: 140px;
      }
  
      &-title {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: normal;
        text-align: center;
      }
  
      &-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0;
  
        &:not(&:nth-child(1)) {
          border-bottom: 1px solid #cecece;
        }
  
        span {
          width: 200px;
          text-align: right;
        }
      }
    }
    &-form {
      background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
      input::placeholder,
      textarea::placeholder {
        color: #fff;
      }
  
      .van-cell {
        background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
        padding: 15px 0;
        color: #333 !important;
        &::after {
          border-bottom: 1px solid #cecece;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
  
      .van-field__label {
        width: 8em;
        color: #333 !important;
        text-align: right;
      }
  
      &-upload {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px 0;
        border-bottom: 1px solid #cecece;
  
        span {
          width: 8.5em;
          text-align: right;
        }
        .van-uploader {
          margin-left: 20px;
  
          .van-uploader__preview {
            margin: 0 6px 6px 0;
          }
        }
      }
    }
  }
  </style>
  