import Vue, { KeepAlive } from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login.vue";
import Details from "../views/det.vue";
import Progress from "../views/pro.vue";
import deductadd from "../views/deductAdd.vue";
import deductList from "../views/deductList.vue";
import deductaList from "../views/deductaList.vue";
import materials from "../views/materials.vue";
import recordDetail from "../views/recordDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/details",
    name: "details",
    component: Details,
  },
  {
    path: "/progress",
    name: "progress",
    component: Progress,
  },
  {
    path: "/deductadd",
    name: "deductadd",
    component: deductadd,
  },
  {
    path: "/deduct",
    name: "deduct",
    component: deductList,
    meta:{
        keepAlive:true
    }
  },
  {
    path: "/deductA",
    name: "deductA",
    component: deductaList,
  },
  {
    path: "/materials",
    name: "materials",
    component: materials,
  },
  {
    path: "/recordDetail",
    name: "recordDetail",
    component:recordDetail,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
